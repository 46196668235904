import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AuthState, IUser } from "./auth.types";
import { hrpToken } from "../api";

const initialState: AuthState = {
  user: null,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      if (process.env.NODE_ENV === "development") {
        localStorage.setItem(hrpToken, action.payload);
      }
    },
    clearToken: (state) => {
      state.token = null;
      localStorage.removeItem(hrpToken);
    },
  },
});

export const { setUser, clearUser, setToken, clearToken } = authSlice.actions;

export default authSlice.reducer;
