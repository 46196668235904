import { FC, useEffect, useRef, useState } from "react";
import { ITextfieldProps } from "./Textfield.types";
import classes from "./Textfield.module.scss";
import clsx from "clsx";
import { DeleteIcon } from "../../icons/Delete";
import { formatNumberValue, unmaskPrice, maskWithPrice } from "../../utilites/methods";
import { Tooltip } from "../Tooltip/Tooltip";

export const Textfield: FC<ITextfieldProps> = ({
  value,
  onChange,
  size = "small",
  name = "",
  className = "",
  autoFocus = false,
  type = "text",
  isPrice = false,
  min,
  max,
  placeholder = "",
  label = "",
  fullWidth = false,
  labelPosition = "top",
  selectOnFocus = false,
  isError = false,
  disabled = false,
  error = "Заполните поле",
  onBlur,
  onKeyPress,
  shouldFocus = false,
  title,
}) => {
  const [currentValue, setCurrentValue] = useState("");
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCurrentValue(value || "");
  }, [value]);

  useEffect(() => {
    if (shouldFocus && input.current) {
      input.current.focus();
    }
  }, [shouldFocus]);

  const valueChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    let v =
      type === "number"
        ? formatNumberValue(evt.currentTarget.value, min, max)
        : evt.currentTarget.value;
    if (isPrice) {
      v = unmaskPrice(v);
    }
    setCurrentValue(v);
    onChange && onChange(v, name);
  };

  const focusHandler = () => {
    selectOnFocus && input?.current?.select();
  };

  const clearClickHandler = () => {
    setCurrentValue("");
    onChange && onChange("", name);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyPress && onKeyPress(event);
  };

  const renderInput = () => (
    <>
      <input
        className={clsx(
          classes.input,
          fullWidth && classes.fullWidth,
          isError && classes.error,
          disabled && classes.disabled,
          size === "small" && classes.small
        )}
        autoComplete="off"
        autoFocus={autoFocus}
        ref={input}
        disabled={disabled}
        value={isPrice ? maskWithPrice(currentValue as string) : currentValue}
        type={type === "password" ? type : "text"}
        onChange={valueChangeHandler}
        onFocus={focusHandler}
        placeholder={placeholder}
        onBlur={onBlur}
        onKeyPress={handleKeyPress}
      />
      {isError && <span className={classes.errorText}>{error}</span>}
      {type === "search" && !!currentValue.length && (
        <button className={classes.clear} onClick={clearClickHandler}>
          <DeleteIcon className={classes.icon} />
        </button>
      )}
    </>
  );

  return title && !value ? (
    <Tooltip title={title}>
      <label
        className={clsx(
          classes.label,
          classes[labelPosition],
          className,
          fullWidth && classes.fullWidth
        )}
      >
        {label}
        {renderInput()}
      </label>
    </Tooltip>
  ) : (
    <label
      className={clsx(
        classes.label,
        classes[labelPosition],
        className,
        fullWidth && classes.fullWidth
      )}
    >
      {label}
      {renderInput()}
    </label>
  );
};
