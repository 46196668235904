import clsx from "clsx";
import { FC, PropsWithChildren, useRef, useState } from "react";
import classes from "./Tooltip.module.scss";
import { ITooltipProps } from "./Tooltip.types";

export const Tooltip: FC<PropsWithChildren<ITooltipProps>> = ({
  children,
  className,
  tooltipClassName,
  title,
  isOnlyForOverflow = false,
  position = "bottom-right",
  onDoubleClick,
}) => {
  const anchor = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const mouseOverHandler: React.MouseEventHandler<HTMLDivElement> = (evt) => {
    if (anchor.current) {
      anchor.current.style.top = `${evt.clientY + 10}px`;
      anchor.current.style.left = `${evt.clientX + 20}px`;
    }
    if (!isOnlyForOverflow) {
      setShow(true);
      return;
    }
    const children = evt.currentTarget;
    if (children instanceof HTMLElement && children.offsetWidth < children.scrollWidth) {
      setShow(true);
    }
  };

  const mouseLeaveHandler: React.MouseEventHandler<HTMLDivElement> = () => {
    setShow(false);
  };

  const mouseMoveHandler: React.MouseEventHandler<HTMLDivElement> = (evt) => {
    if (anchor.current && show) {
      switch (position) {
        case "bottom-right":
          anchor.current.style.top = `${evt.clientY + 20}px`;
          anchor.current.style.left = `${evt.clientX + 20}px`;
          break;
        case "top-left":
          anchor.current.style.top = `${evt.clientY - anchor.current.offsetHeight + 20}px`;
          anchor.current.style.left = `${evt.clientX - anchor.current.offsetWidth + 10}px`;
          break;
        case "top-right":
          anchor.current.style.top = `${evt.clientY - anchor.current.offsetHeight + 20}px`;
          anchor.current.style.left = `${evt.clientX + 10}px`;
          break;
        case "bottom-left":
          anchor.current.style.top = `${evt.clientY + 20}px`;
          anchor.current.style.left = `${evt.clientX - anchor.current.offsetWidth + 10}px`;
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <div className={clsx(classes.tooltip, show && classes.show, tooltipClassName)} ref={anchor}>
        {title}
      </div>
      <div
        className={clsx(classes.container, className)}
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
        onMouseMove={mouseMoveHandler}
        onDoubleClick={onDoubleClick}
      >
        {children}
      </div>
    </>
  );
};
