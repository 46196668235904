import { api } from "../api";
import {
  IGetLoginResponse,
  ILoginRequest,
  ILoginResponse,
  ILogoutResponse,
  IUser,
} from "./auth.types";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLogin: builder.mutation<IGetLoginResponse, void>({
      query: () => ({
        url: "login",
        method: "GET",
      }),
    }),
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (body) => ({
        url: "login",
        method: "POST",
        body,
      }),
    }),
    passwordReset: builder.mutation<void, string>({
      query: (email) => ({
        url: "user/password/reset",
        method: "POST",
        body: { email },
      }),
    }),
    logout: builder.mutation<ILogoutResponse, void>({
      query: (credentials) => ({
        url: "login",
        method: "DELETE",
        body: credentials,
      }),
    }),
    userInfo: builder.mutation<IUser, void>({
      query: () => ({
        url: "auth/info",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetLoginMutation,
  useLoginMutation,
  usePasswordResetMutation,
  useLogoutMutation,
  useUserInfoMutation,
} = authApi;
