import { FC } from "react";
import classes from "./SpinnerSmall.module.scss";
import { ISpinnerSmallProps } from "./SpinnerSmall.types";

export const SpinnerSmall: FC<ISpinnerSmallProps> = ({ isLoading, color = "#869DCD" }) => {
  if (!isLoading) return null;
  return (
    <div className={classes.ldsEllipsis}>
      <div style={{background: color}}></div>
      <div style={{background: color}}></div>
      <div style={{background: color}}></div>
      <div style={{background: color}}></div>
    </div>
  );
};
