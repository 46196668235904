export const formatNumberValue = (
  value: string,
  min: number | undefined,
  max: number | undefined
) => {
  let result = value.match(/[0-9\\., ]/g)?.join("");

  if (min && Number(result) < min) {
    result = `${min}`;
  }

  if (max && Number(result) > max) {
    result = `${max}`;
  }

  return result || "";
};

export const maskWithPrice = (value: string) => {
  return Number(value).toLocaleString("ru-RU");
};

export const unmaskPrice = (value: string) => {
  return value.replaceAll(" ", "");
};
