import { FC, useEffect } from "react";
import classes from "./Dashboard.module.scss";
import { getUserSelector } from "../../redux/auth/auth.selectors";
import { useTypedSelector } from "../../redux/store.hooks";
import { useAuth } from "../../redux/auth/auth.hooks";
import { Link } from "react-router-dom";

export const Dashboard: FC = (props) => {
  const { getLogin, logoutHandler } = useAuth();
  const user = useTypedSelector(getUserSelector);

  useEffect(() => {
    getLogin();
  }, []);

  return (
    <div className={classes.block}>
      <div className={classes.body}>
        <div className={classes.title}>Личный кабинет</div>
        <div className={classes.user}>{JSON.stringify(user)}</div>
        <Link to="https://relevanter.oliviahr.ru" className={classes.link} target="true">
          Relevanter
        </Link>
        <button className={classes.logout} onClick={logoutHandler}>
          Выйти
        </button>
      </div>
    </div>
  );
};
