import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Register } from "./pages/Register/Register";
import ChangePassword from "./pages/ChangePassword/ChangePassword";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "changePassword",
    element: <ChangePassword />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
]);
