import { FC, PropsWithChildren } from "react";
import { IButtonProps } from "./Button.types";
import classes from "./Button.module.scss";
import clsx from "clsx";
import { SpinnerSmall } from "../SpinnerSmall/SpinnerSmall";
import { Tooltip } from "../Tooltip/Tooltip";

export const Button: FC<PropsWithChildren<IButtonProps>> = ({
  children,
  type = "button",
  className = "",
  disabled,
  fullWidth = false,
  isLoading = false,
  title = null,
  size = "normal",
  onClick = () => {},
  variant = "primary",
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        classes.button,
        classes[variant],
        classes[size],
        fullWidth && classes.fullWidth,
        className
      )}
    >
      {title ? (
        <Tooltip title={title}>
          <span className={clsx(classes.content, isLoading && classes.loading)}>
            {children}
          </span>
        </Tooltip>
      ) : (
        <span className={clsx(classes.content, isLoading && classes.loading)}>
          {children}
        </span>
      )}
      <div className={classes.spinner}>
        <SpinnerSmall isLoading={isLoading} />
      </div>
    </button>
  );
};
