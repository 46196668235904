import { FC } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import classes from "./Register.module.scss";

const validationSchema = Yup.object({
  login: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

export const Register: FC = (props) => {

  const initialValues = {
    login: "",
    password: "",
  };

  const onSubmit = (values: { login: string; password: string }) => {
    console.log(values);
  };

  return (
    <div className={classes.block}>
      <div className={classes.body}>
        <div className={classes.form}>
          <div className={classes.title}>Register</div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className={classes.input}>
                <Field name="login" type="text" placeholder="login" />
                <ErrorMessage name="login" component="div" className={classes.error} />
              </div>
              <div className={classes.input}>
                <Field name="password" type="text" placeholder="password" />
                <ErrorMessage name="password" component="div" className={classes.error} />
              </div>
              <button type="submit" className={classes.btn}>
                Отправить
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};
