import { useMemo } from "react";
import { useNavigate } from "react-router";
import { handleError } from "../api";
import { useAppDispatch, useTypedSelector } from "../store.hooks";
import { getUserSelector } from "./auth.selectors";
import {
  useGetLoginMutation,
  useLoginMutation,
  useLogoutMutation,
  usePasswordResetMutation,
} from "./auth.service";
import { clearToken, setToken, setUser } from "./auth.slice";
import { toast } from "react-toastify";

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getLoginRequest, { isLoading: isGetLoginLoading }] = useGetLoginMutation();
  const [loginRequest, { isLoading: isLoginLoading }] = useLoginMutation();
  const [resetPasswordRequest, { isLoading: isResetLoading }] =
    usePasswordResetMutation();
  const [logout, { isLoading: isLogoutLoading }] = useLogoutMutation();
  const user = useTypedSelector(getUserSelector);

  const getLogin = async () => {
    try {
      const res = await getLoginRequest().unwrap();
      if (res.data) {
        dispatch(setUser(res.data.user));
        navigate("/dashboard");
      }
    } catch (err) {
      handleError(err);
      navigate("/");
    }
  };

  const loginHandler = async (login: string, password: string) => {
    if (!user) {
      try {
        const { data } = await loginRequest({
          login,
          password,
        }).unwrap();

        if (data) {
          dispatch(setToken(data.token));
          navigate("/dashboard");
        }
      } catch (err) {
        handleError(err);
      }
    }
  };

  const checkEmail = (email: string) => {
    resetPasswordRequest(email).then(() => {
      toast.success("На адрес электронной почты было выслано письмо для сброса пароля");
    });
  };

  const logoutHandler = async () => {
    try {
      logout().unwrap();
      navigate("/");
      dispatch(clearToken());
    } catch (err) {
      handleError(err);
    }
  };

  const data = useMemo(
    () => ({
      isLoginLoading,
      isGetLoginLoading,
      isResetLoading,
      isLogoutLoading,
    }),
    [isLoginLoading, isGetLoginLoading, isResetLoading, isLogoutLoading]
  );

  return {
    ...data,
    getLogin,
    loginHandler,
    checkEmail,
    logoutHandler,
  };
};
