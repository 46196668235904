import { FC, useEffect, useState } from "react";
import classes from "./Login.module.scss";
import { useAuth } from "../../redux/auth/auth.hooks";
import { Textfield } from "../../common/ui/Textfield/Textfield";
import { Button } from "../../common/ui/Button/Button";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export const Login: FC = (props) => {
  const { loginHandler, isLoginLoading, getLogin } = useAuth();

  const [loginState, setLoginState] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    loginHandler(loginState, password);
  };

  useEffect(() => {
    getLogin();
  }, []);

  return (
    <div className={classes.container}>
      <h1 className={classes.mainTitle}>Olivia Center</h1>
      <h2 className={classes.title}>Вход в аккаунт</h2>
      <form className={classes.form} onSubmit={submitHandler}>
        <Textfield
          value={loginState}
          size="medium"
          placeholder="Логин"
          name="login"
          selectOnFocus
          onChange={(value) => setLoginState(value)}
        />
        <Textfield
          value={password}
          size="medium"
          placeholder="Пароль"
          type="password"
          name="password"
          selectOnFocus
          onChange={(value) => setPassword(value)}
        />
        <Button type="submit" variant="secondary" isLoading={isLoginLoading}>
          Войти
        </Button>
        <p className={classes.registration}>
          Забыли пароль?
          <Link to="/changePassword" className={classes.link}>
            Сменить пароль
          </Link>
        </p>
        <p className={classes.registration}>
          Нет аккаунта?
          <Link to="/signup" className={classes.link}>
            {" "}
            Зарегистрируйтесь
          </Link>
        </p>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        limit={3}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
