import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { RootState } from "./store";

//@ts-ignore
export const baseURL = process.env.REACT_APP_BASE_URL;
export const hrpToken = "hrpToken";

export const handleError = (err: any, notify: boolean = true) => {
  let message = "Unknown Error";
  if (err instanceof Error) {
    message = err.message;
  }
  if (err.data) {
    if (err.data.message && typeof err.data.message === "string") {
      message = err.data.message;
    }
    if (err.data.error) {
      message = err.data.error.message;
    }
    if (err.data.message && Array.isArray(err.data.message)) {
      message = err.data.message.join("\n");
    }
  } else if (err.error) {
    message = err.error;
  } else {
    console.log(err);
  }
  notify ? toast.error(message) : console.log(message);
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token || localStorage.getItem(hrpToken);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  endpoints: () => ({}),
});
