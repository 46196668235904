import classes from "./App.module.scss";
import { Login } from "./pages/Login/Login";

function App() {
  return (
    <section className={classes.wrapper}>
      <Login />
    </section>
  );
}

export default App;
