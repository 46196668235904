import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
// import { OliviaStaticIcon } from "../../common/icons/OliviaStatic";
import { Button } from "../../common/ui/Button/Button";
import { Textfield } from "../../common/ui/Textfield/Textfield";
import { useAuth } from "../../redux/auth/auth.hooks";
import classes from "./ChangePassword.module.scss";

export const ChangePassword = () => {
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { checkEmail } = useAuth();

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Введите email аккаунта");
      return;
    }
    checkEmail(email);
    setDisabled(true);
  };

  const checkValidity = (value: string) => {
    const EMAIL_REGEXP =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    setValid(EMAIL_REGEXP.test(value));
  };

  useEffect(() => checkValidity(email), [email]);

  return (
    <div className={classes.container}>
      <h1 className={classes.mainTitle}>
        Olivia Center
        {/* <OliviaStaticIcon className={classes.olivia} /> */}
      </h1>
      <h2 className={classes.title}>Смена пароля</h2>
      <p className={classes.description}>
        Для смены пароля введите адрес электронной почты, к которому привязан аккаунт
      </p>
      <form className={classes.form} onSubmit={submitHandler}>
        <Textfield
          size="medium"
          placeholder="Email"
          type="text"
          name="email"
          value={email}
          selectOnFocus
          onChange={(value) => {
            setEmail(value);
            setDisabled(false);
          }}
        />
        <Button type="submit" variant="secondary" disabled={!valid || disabled}>
          Подтвердить
        </Button>
        <p className={classes.registration}>
          <Link to="/" className={classes.link}>
            Назад
          </Link>
        </p>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        limit={3}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ChangePassword;
